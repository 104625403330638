import React, { useState } from "react";
import { BsCheckCircleFill } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import { signupStep1,validateEmailOTP } from '../api/Api';
import toast, { Toaster } from "react-hot-toast";
import { ACCESS_TOKEN, ADMIN_TOKEN} from "../config/Config";
import { useContextSelector } from "use-context-selector";
import { StateContext } from "../contexts/StateContext";
import Navbar from './Navbar';
import { categories } from "../data/data";

const SignUp = () => {
  const isLoggedIn = useContextSelector(
    StateContext,
    (state) => state.isLoggedIn
  );
  const setIsLoggedIn = useContextSelector(
    StateContext,
    (state) => state.setIsLoggedIn
  );
  const setRole = useContextSelector(
    StateContext,
    (state) => state.setRole
  );
  const navigate = useNavigate();
  // ============= Initial State Start here =============
  const [clientName, setClientName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [zip, setZip] = useState("");
  const [checked, setChecked] = useState(false);
  const[course, setCourse] = useState("tnpsc");
  const [otpPageEnabled, setOtpPageEnabled] = useState(false);
  // ============= Initial State End here ===============
  // ============= Error Msg Start here =================
  const [errClientName, setErrClientName] = useState("");
  const [errEmail, setErrEmail] = useState("");
  const [errPhone, setErrPhone] = useState("");
  const [errPassword, setErrPassword] = useState("");
  const [errAddress, setErrAddress] = useState("");
  const [errCity, setErrCity] = useState("");
  const [errCountry, setErrCountry] = useState("");
  const [errZip, setErrZip] = useState("");
  const [errCourse, setErrCourse] = useState("");
  const [userCategory, setUserCategory] = useState("User");
  // ============= Error Msg End here ===================
  const [successMsg, setSuccessMsg] = useState("");
  // ============= Event Handler Start here =============
  const [otp, setOtp] = useState("");
  const [errOTP, setErrOTP] = useState("");

  const handleOtpChange = (e) => {
    setOtp(e.target.value);
    setErrOTP("");
  };

  const handleVerifyOTP = () => {
    // Validate OTP logic here
    if (!otp) {
      setErrOTP("Enter the OTP");
    } else {
      // Call API to validate OTP
      const otpValidationRequest = {
        email: email,
        otp: otp,
        mobileAsPassword:phone,
      
      };

      validateEmailOTP(otpValidationRequest)
        .then((res) => {
          // Handle successful OTP validation
          localStorage.setItem(
            ACCESS_TOKEN,
            res.accessToken ? res.accessToken : null
          );
          setIsLoggedIn(true);
        let token = res.accessToken;
        //alert("outside"+JSON.stringify(token));
        const base64Url = token.split(".")[1];
       // alert("baseData"+JSON.stringify(base64Url))
        const base64 = base64Url.replace("-", "+").replace("_", "/");
       // alert("base"+JSON.stringify(base64));
        token = JSON.parse(window.atob(base64));
       // alert("token genra"+JSON.stringify(token));
        //console.log(exp, Math.floor(Date.now()/1000));
        
        if (token.exp <= Math.floor(Date.now() / 1000)) {
          localStorage.removeItem(ACCESS_TOKEN);
          localStorage.removeItem(ADMIN_TOKEN);
          // showAlert('Your session has expired !', 'info');
          // this.props.history.push("/");
          //alert("token role from login.js" + token.rol[0]);
          isLoggedIn(false);
        }

          if (token.rol.length >= 1) {
           // alert("token role from login.js" + token.rol[0]);
            setRole(token.rol[0]);
          }
          //toast(res.message);
         /*  Enable this to set loggedIn or role
         setIsLoggedIn(true);
          let token = res.accessToken;
          //alert("outside"+JSON.stringify(token));
          const base64Url = token.split(".")[1];
          //alert("baseData"+JSON.stringify(base64Url))
          const base64 = base64Url.replace("-", "+").replace("_", "/");
          // alert("base"+JSON.stringify(base64));
          token = JSON.parse(window.atob(base64));
          //alert("token genra"+JSON.stringify(token));
          // console.log(exp, Math.floor(Date.now()/1000));
          if (token.exp <= Math.floor(Date.now() / 1000)) {
            localStorage.removeItem(ACCESS_TOKEN);
            localStorage.removeItem(ADMIN_TOKEN);
          
            // showAlert('Your session has expired !', 'info');
            // this.props.history.push("/");
          
            isLoggedIn(false);
            
          }
          if (token.rol.length >= 1) {
           // alert("token role from login.js" + token.rol[0]);
            setRole(token.rol[0]);
          }*/
          navigate("/")
          // You can perform additional actions after successful OTP validation
          
        })
        .catch((error) => {
          // Handle OTP validation error
          toast("Invalid OTP. Please try again!", "error");
          console.error(error);
        });
    }
  };
  const handleName = (e) => {
    setClientName(e.target.value);
    setErrClientName("");
  };
  const handleEmail = (e) => {
    setEmail(e.target.value);
    setErrEmail("");
  };
  const handlePhone = (e) => {
    setPhone(e.target.value);
    setErrPhone("");
  };
  const handlePassword = (e) => {
    setPassword(e.target.value);
    setErrPassword("");
  };
  const handleAddress = (e) => {
    setAddress(e.target.value);
    setErrAddress("");
  };
  const handleCity = (e) => {
    setCity(e.target.value);
    setErrCity("");
  };
  const handleCountry = (e) => {
    setCountry(e.target.value);
    setErrCountry("");
  };
  const handleZip = (e) => {
    setZip(e.target.value);
    setErrZip("");
  };
  const handleCourse = (e) => {
    setCourse(e.target.value);
    setErrCourse("");
  };
  // ============= Event Handler End here ===============
  // ================= Email Validation start here =============
  const EmailValidation = (email) => {
    return String(email)
      .toLowerCase()
      .match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i);
  };
  // ================= Email Validation End here ===============

  const handleSignUp = (e) => {
    e.preventDefault();
    if (checked) {
      if (!clientName) {
        setErrClientName("Enter your name");
      }
      if (!email) {
        setErrEmail("Enter your email");
      } else {
        if (!EmailValidation(email)) {
          setErrEmail("Enter a Valid email");
        }
      }
      if (!phone) {
        setErrPhone("Enter your phone number");
      }
      if (!password) {
        setErrPassword("Create a password");
      } else {
        if (password.length < 6) {
          setErrPassword("Passwords must be at least 6 characters");
        }
      }
      if (!address) {
        setErrAddress("Enter your address");
      }
      if (!city) {
        setErrCity("Enter your city name");
      }
      if (!country) {
        setErrCountry("Enter the country you are residing");
      }
      if (!zip) {
        setErrZip("Enter the zip code of your area");
      }
      if(!course) {
        setErrCourse("Choose the correct option of the course")
      }
      // ============== Getting the value ==============
      if (
        clientName &&
        email &&
        EmailValidation(email) &&
        phone &&
        course
      ) {
        //
        const signUpRequest={
          name:clientName,
          email:email,
          mobile:phone,
          password:phone,
          course: course,
          orgName: "foodfly",
          category:userCategory === "pupil" ? "pupil" : "shop"
        }
        signUpRequest.name=clientName;
        signUpRequest.email=email;
        signUpRequest.course=course;
        signUpRequest.mobile=phone;
        signUpRequest.password=phone;
       signUpRequest.orgName="foodfly";
       signUpRequest.category=userCategory;
        //alert("before backend call"+JSON.stringify(signUpRequest));
        signupStep1(signUpRequest)
          .then((res) => {
            //alert("after backend call"+JSON.stringify(res));
            if(res.message==='Your Fresh signup request has been accepted ! Please check your email and enter the otp to complete the signup! OTP is valid for 10 minutes.')
            {
              // otp enter page
              setSuccessMsg(
                `Hello dear ${clientName}, Welcome you to FOODFLY portal. We received your Sign up request. We are processing to validate your access. 
                Please check your email and enter the email otp to signup. ${email}`
              );
              setOtpPageEnabled(true);
              toast.success(
                res.message
              );
            } else{
              toast.error("Network error, your signup failed. Please try again.")
            }      
          })
          .catch((error) => {
            if (error.message) {
              toast.error(error.message,"error");
            } else {
              toast.error("Sorry! Something went wrong. Please try again!","error");
              
            }
           // setShowLoading(false);
            console.log(error);
          });
        
        setClientName("");
        
        setPassword("");
        setAddress("");
        setCity("");
        setCountry("");
        setZip("");
      }
    }
  };
 return (
  <>
    <Navbar />
    <div className="max-w-[800px] bg-white mx-auto p-10 mb-20 rounded-2xl shadow-lg shadow-black/20">
      <div className="w-full flex justify-center">
        {otpPageEnabled ? (
          <div className="max-w-md mx-auto mt-20 p-6 shadow-lg bg-gradient-to-r from-pink-300 via-purple-300 to-indigo-400 rounded-xl">
            <h2 className="text-2xl font-bold text-gray-800 mb-4">Email OTP Verification</h2>
            <p className="text-gray-700">
              Hello dear {clientName}, We sent an OTP to {email}. Please enter the OTP to complete the signup process.
            </p>
            <input
              type="text"
              value={otp}
              onChange={handleOtpChange}
              placeholder="Enter OTP"
              className="rounded-full bg-gray-200 py-3 px-5 w-full mt-4 focus:outline-none focus:ring-2 focus:ring-indigo-500"
            />
            {errOTP && <p className="text-red-500 mt-2">{errOTP}</p>}
            <button
              onClick={handleVerifyOTP}
              className="w-full mt-4 py-2 bg-blue-500 text-white rounded-full hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-indigo-500"
            >
              Verify OTP
            </button>
            <p className="mt-4 text-sm text-gray-700">
              Didn't receive the OTP?{" "}
              <Link to="/resendotp" className="text-blue-500">
                Resend OTP
              </Link>
            </p>
            <Toaster
              position="bottom-right"
              reverseOrder={false}
              toastOptions={{
                error: {
                  duration: 6000,
                  style: {
                    background: "#FF4136",
                    color: "#fff",
                  },
                },
                success: {
                  duration: 6000,
                  style: {
                    background: "#008000",
                    color: "#fff",
                  },
                },
              }}
            />
          </div>
        ) : (
          <form className="text-center mt-20 mb-5 text-xl text-purple-700">
            <div className="px-6 py-4 flex flex-col">
            <button
                    className="bg-gray-300 py-2 px-4 mb-4 rounded full text-black hover:bg-gray-500"
                    onClick={() => navigate("/#")} // Go back to the previous page
                >
                    Back
                </button>
              <h1 className="font-titleFont underline underline-offset-4 decoration-2 font-semibold text-2xl mdl:text-3xl mb-6">
                Create your account
              </h1>
              <div className="flex flex-col gap-6">
                {/* Full Name */}
                <div>
                  <label className="block text-xl mb-2 text-gray-700" htmlFor="fullName">
                    Full Name*
                  </label>
                  <input
                    type="text"
                    onChange={handleName}
                    value={clientName}
                    id="fullName"
                    placeholder="Enter Full Name"
                    className="rounded-full bg-gray-200 py-3 px-5 w-full focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  />
                  {errClientName && (
                    <p className="text-sm text-red-500 font-titleFont font-semibold mt-2">
                      {errClientName}
                    </p>
                  )}
                </div>

                {/* Email */}
                <div>
                  <label className="block text-xl mb-2 text-gray-700" htmlFor="email">
                    Email*
                  </label>
                  <input
                    type="email"
                    onChange={handleEmail}
                    value={email}
                    id="email"
                    placeholder="Enter Email Address"
                    className="rounded-full bg-gray-200 py-3 px-5 w-full focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  />
                  {errEmail && (
                    <p className="text-sm text-red-500 font-titleFont font-semibold mt-2">
                      {errEmail}
                    </p>
                  )}
                </div>

                {/* Mobile Number */}
                <div>
                  <label className="block text-xl mb-2 text-gray-700" htmlFor="mobile">
                    Mobile Number*
                  </label>
                  <input
                    type="tel"
                    onChange={handlePhone}
                    value={phone}
                    id="mobile"
                    placeholder="Enter Your Mobile Number"
                    className="rounded-full bg-gray-200 py-3 px-5 w-full focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  />
                  {errPhone && (
                    <p className="text-sm text-red-500 font-titleFont font-semibold mt-2">
                      {errPhone}
                    </p>
                  )}
                </div>
                <select
              id="category"
              value={userCategory}
              onChange={(e) => setUserCategory(e.target.value)} // Update selected category
              className="rounded-full bg-gray-200 py-3 px-5 w-full focus:outline-none focus:ring-2 focus:ring-indigo-500"
            >
              <option value="pupil">User</option>
              <option value="shop">Shop Owner</option>
            </select>

                {/* Terms and Conditions */}
                <div className="flex items-start gap-2">
                  <input
                    onChange={() => setChecked(!checked)}
                    className="w-5 h-5 mt-1 cursor-pointer focus:ring-indigo-500"
                    type="checkbox"
                  />
                  <p className="text-sm text-gray-700">
                    I agree to the Foodfly{" "}
                    <Link to="/Terms" className="text-blue-500">
                      Terms of Service
                    </Link>{" "}
                    and{" "}
                    <span className="text-blue-500">Privacy Policy</span>.
                  </p>
                </div>

                {/* Submit Button */}
                <button
                  onClick={handleSignUp}
                  className={`${
                    checked
                      ? "bg-indigo-500 hover:bg-indigo-600 text-white"
                      : "bg-gray-400 text-gray-200 cursor-not-allowed"
                  } w-full py-3 rounded-full text-base font-medium focus:outline-none focus:ring-2 focus:ring-indigo-500`}
                  disabled={!checked}
                >
                  Create Account
                </button>
                <p className="text-sm text-center font-titleFont font-medium mt-4 text-gray-700">
                  Already have an account?{" "}
                  <Link to="/signin" className="text-blue-500 hover:text-blue-600 duration-300">
                    Sign in
                  </Link>
                </p>
              </div>
            </div>
          </form>
        )}
      </div>
    </div>
  </>
);

};

export default SignUp;
