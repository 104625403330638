import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import {
  foodFlyOrderStatusUpdate,
  getFoodFlyDataByCategory,
} from "../../src/api/PupilApi"; // Adjust API import path as necessary
import "./grid.css";

const DEFAULT_CATEGORY = "shop"; // Set default category to "shop"
const DEFAULT_STATUS_FILTER = ""; // Default filter for status
const ORDERS_PER_PAGE = 15; // Number of orders per page

function AdminDashboard() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [cancelReason, setCancelReason] = useState("");
  const [updatingStatus, setUpdatingStatus] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(DEFAULT_CATEGORY); // State for category
  const [statusFilter, setStatusFilter] = useState(DEFAULT_STATUS_FILTER); // State for status filter
  const [currentPage, setCurrentPage] = useState(1); // State for current page

  useEffect(() => {
    loadOrdersByCategory(selectedCategory); // Load orders by the default category on mount
  }, [selectedCategory]);

  const loadOrdersByCategory = (category) => {
    setLoading(true);
    getFoodFlyDataByCategory(category)
      .then((res) => {
        toast.success(res.data.message);
        const ordersData = res.data.data.flatMap((pupil) =>
          pupil.orderList ? pupil.orderList : [] // Ensure orderList is not null
        );
        setOrders(ordersData);
      })
      .catch((error) => {
        toast.error("Failed to load orders. Please try again.");
        console.error(error);
      })
      .finally(() => setLoading(false));
  };

  const openModal = (order) => {
    setSelectedOrder(order);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setSelectedOrder(null);
    setCancelReason("");
  };

  const handleStatusUpdate = async (orderId, newStatus) => {
    if (newStatus === "Cancelled" && !cancelReason) {
      alert("Please provide a reason for cancellation.");
      return;
    }

    setUpdatingStatus(true);

    try {
      const payload = {
        orderId,
        orderedUserName: selectedOrder.orderedUserName,
        shopEmail: selectedOrder.shopEmail,
        status: newStatus,
        reason: newStatus === "Cancelled" ? cancelReason : "",
      };

      await foodFlyOrderStatusUpdate(orderId, payload);
      loadOrdersByCategory(selectedCategory); // Reload orders after status update
      toast.success("Order status updated successfully!");
    } catch (error) {
      console.error("Error updating order status:", error);
      toast.error("Failed to update order status.");
    } finally {
      setUpdatingStatus(false);
      setCancelReason("");
    }
  };

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value); // Update selected category
    setCurrentPage(1); // Reset to first page on category change
  };

  const handleStatusFilterChange = (e) => {
    setStatusFilter(e.target.value); // Update selected status filter
    setCurrentPage(1); // Reset to first page on filter change
  };

  // Filtered orders based on status
  const filteredOrders = statusFilter
    ? orders.filter((order) => order.orderStatus.toLowerCase() === statusFilter.toLowerCase())
    : orders;

  // Pagination logic
  const totalPages = Math.ceil(filteredOrders.length / ORDERS_PER_PAGE);
  const displayedOrders = filteredOrders.slice(
    (currentPage - 1) * ORDERS_PER_PAGE,
    currentPage * ORDERS_PER_PAGE
  );

  return (
    <div className="control-pane flex-1 m-5">
      <div className="control-section">
        <h2 className="text-2xl font-semibold mb-4">Admin Dashboard - Order Management</h2>

        {/* Back to Home Button */}
        <button
          onClick={() => navigate("/")} // Adjust the route as necessary
          className="bg-blue-500 text-white px-4 py-2 rounded mb-4 hover:bg-blue-600"
        >
          Back to Home
        </button>

        {/* Category Selection */}
        <div className="mb-4">
          <label className="mr-2">Select Category:</label>
          <select value={selectedCategory} onChange={handleCategoryChange} className="border p-2 rounded">
            <option value="shop">Shop Orders</option>
            <option value="pupil">User Orders</option>
            {/* Add more categories as needed */}
          </select>
        </div>

        {/* Status Filter */}
        <div className="mb-4">
          <label className="mr-2">Filter by Status:</label>
          <select value={statusFilter} onChange={handleStatusFilterChange} className="border p-2 rounded">
            <option value="">All</option>
            <option value="Pending">Pending</option>
            <option value="Preparing">Preparing</option>
            <option value="On the way">On the way</option>
            <option value="Delivered">Delivered</option>
            <option value="Cancelled">Cancelled</option>
          </select>
        </div>

        {loading ? (
          <p>Loading orders...</p>
        ) : (
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Shop Name
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Order Person Name
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Order Person Mobile
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Quantity
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Order Status
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    View Order List
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {displayedOrders.length === 0 ? (
                  <tr>
                    <td colSpan="6" className="px-6 py-4 text-sm text-gray-500 text-center">
                      No orders available
                    </td>
                  </tr>
                ) : (
                  displayedOrders.map((order) => (
                    <tr key={order.order_id}>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        {order.shopName}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {order.orderedPersonName}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {order.orderedPersonMobile}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {order.quantity}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        <button
                          className={`px-2 py-1 rounded-lg font-bold text-white ${
                            order.orderStatus === "Pending" ? "bg-blue-500 hover:bg-blue-600" :
                            order.orderStatus === "Preparing" ? "bg-yellow-500 hover:bg-yellow-600" :
                            order.orderStatus === "On the way" ? "bg-green-500 hover:bg-green-600" :
                            order.orderStatus === "Delivered" ? "bg-purple-500 hover:bg-purple-600" :
                            order.orderStatus === "Cancelled" ? "bg-red-500 hover:bg-red-600" :
                            "bg-gray-500 hover:bg-gray-600" // fallback for any unexpected status
                          }`}
                        >
                          {order.orderStatus}
                        </button>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        <button
                          onClick={() => openModal(order)}
                          className="text-blue-500 underline"
                        >
                          View Details
                        </button>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        )}

        {/* Pagination Controls */}
        {totalPages > 1 && (
          <div className="mt-4 flex justify-between items-center">
            <button
              onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
              disabled={currentPage === 1}
              className="bg-gray-200 px-4 py-2 rounded disabled:opacity-50"
            >
              Previous
            </button>
            <span>{`Page ${currentPage} of ${totalPages}`}</span>
            <button
              onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
              disabled={currentPage === totalPages}
              className="bg-gray-200 px-4 py-2 rounded disabled:opacity-50"
            >
              Next
            </button>
          </div>
        )}
      </div>

      {/* Modal for Order */}
      {modalOpen && selectedOrder && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center p-4">
          <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-3xl relative">
            <div className="space-y-4">
              <h2 className="text-xl font-bold mb-4">Order Details</h2>
              <div className="overflow-y-auto max-h-[60vh]">
                <ul className="space-y-4">
                  <li className="p-4 border border-gray-200 rounded-lg">
                    <p><strong>Order ID:</strong> {selectedOrder.order_id}</p>
                    <p><strong>Shop Name:</strong> {selectedOrder.shopName}</p>
                    <p><strong>Ordered Person:</strong> {selectedOrder.orderedPersonName}</p>
                    <p><strong>Mobile:</strong> {selectedOrder.orderedPersonMobile}</p>
                    <p><strong>Payment Method:</strong> {selectedOrder.paymentMethod}</p>
                    <p><strong>Total Cost:</strong> {selectedOrder.totalCost}</p>
                    <p><strong>Shipping Charge:</strong> {selectedOrder.shippingCharge}</p>

                    <button
                      className={`px-4 py-2 rounded-lg font-bold text-white ${
                        selectedOrder.orderStatus === "Cancelled"
                          ? "bg-red-600 hover:bg-red-700"
                          : selectedOrder.orderStatus === "Delivered"
                          ? "bg-green-600 hover:bg-green-700"
                          : "bg-gray-800 hover:bg-gray-700"
                      }`}
                    >
                      <strong>Order Status:</strong> {selectedOrder.orderStatus}
                    </button>

                    {selectedOrder.image && (
                      <div className="mt-4">
                        <strong>Food:</strong>
                        <img
                          src={selectedOrder.image}
                          alt="Food item"
                          className="w-48 h-32 object-cover rounded-lg mt-2"
                        />
                      </div>
                    )}

                    <p><strong>Description:</strong> {selectedOrder.description}</p>

                    <div className="mt-4">
                      <p className="font-semibold">Update Order Status:</p>
                      <div className="space-x-2">
                        {["Pending", "Preparing", "On the way", "Delivered", "Cancelled"].map((status) => (
                          <button
                            key={status}
                            onClick={() => handleStatusUpdate(selectedOrder.order_id, status)}
                            className={`px-4 py-2 text-white rounded-lg ${
                              selectedOrder.orderStatus === status || updatingStatus
                                ? "bg-gray-400 cursor-not-allowed"
                                : {
                                    Pending: "bg-blue-500 hover:bg-blue-600",
                                    Preparing: "bg-yellow-500 hover:bg-yellow-600",
                                    "On the way": "bg-green-500 hover:bg-green-600",
                                    Delivered: "bg-purple-500 hover:bg-purple-600",
                                    Cancelled: "bg-red-500 hover:bg-red-600",
                                  }[status]
                            }`}
                            disabled={selectedOrder.orderStatus === status || updatingStatus}
                          >
                            {status}
                          </button>
                        ))}
                      </div>

                      {selectedOrder.orderStatus === "Cancelled" && (
                        <div className="mt-4">
                          <textarea
                            value={cancelReason}
                            onChange={(e) => setCancelReason(e.target.value)}
                            placeholder="Enter reason for cancellation"
                            className="border p-2 rounded w-full"
                          />
                        </div>
                      )}
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <button
              onClick={closeModal}
              className="absolute top-4 right-4 bg-gray-200 p-2 rounded-full hover:bg-gray-300"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default AdminDashboard;
