import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { toast } from "react-hot-toast";
import { retrieveFoodFlyOrder } from "../../src/api/PupilApi"; // Adjust this path as necessary
import { USERNAMEWITHMAIL } from "../config/Config";
import "./grid.css";

function UserDashboard() {
  const location = useLocation();
  const navigate = useNavigate();
  const { name, email } = location.state || {}; // Get name and email from location state
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [statusFilter, setStatusFilter] = useState(""); // Added state for status filter
  const ordersPerPage = 20; // Limit to 20 orders per page

  useEffect(() => {
    loadData();
  }, [currentPage]);

  const loadData = () => {
    setLoading(true);
    const userName = localStorage.getItem(USERNAMEWITHMAIL);
    retrieveFoodFlyOrder(userName)
      .then((res) => {
        toast.success(res.data.message);
        setData(res.data.data);
        setLoading(false);
      })
      .catch((error) => {
        toast.error(error.message || "Sorry! Something went wrong. Please try again!");
        setLoading(false);
        console.error(error);
      });
  };

  // Handle filtering by status
  const handleFilterChange = (event) => {
    setStatusFilter(event.target.value); // Update the status filter when the user selects an option
    setCurrentPage(1); // Reset to first page when filter changes
  };

  // Apply filter based on selected status
  const filteredOrders = statusFilter
    ? data.filter((order) => order.orderStatus === statusFilter)
    : data;

  // Pagination logic
  const handleNextPage = () => {
    if (currentPage * ordersPerPage < filteredOrders.length) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const indexOfLastOrder = currentPage * ordersPerPage;
  const indexOfFirstOrder = indexOfLastOrder - ordersPerPage;
  const currentOrders = filteredOrders.slice(indexOfFirstOrder, indexOfLastOrder);

  const handleClickBack = () => {
    navigate("/");
  };

  // Function to highlight order status with new statuses
  const getOrderStatusClass = (status) => {
    switch (status) {
      case "Pending":
        return "text-yellow-600 font-semibold";
      case "Preparing":
        return "text-blue-600 font-semibold";
      case "On the Way":
        return "text-purple-600 font-semibold";
      case "Delivered":
        return "text-green-600 font-semibold";
      case "Cancelled":
        return "text-red-600 font-semibold";
      default:
        return "text-gray-600 font-medium";
    }
  };

  return (
    <div className="control-pane flex-1 m-5">
      <div className="control-section">
        <div className="flex items-center mb-3">
          <h2 className="name pr-3 font-medium">
            Name:<span className="bg-dark font-semibold"> {name}</span>
          </h2>
          <button
            className="select-none rounded-lg float-right bg-black text-white py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase shadow-md transition-all hover:shadow-lg focus:opacity-[0.85] active:opacity-[0.85] disabled:pointer-events-none disabled:opacity-50"
            onClick={handleClickBack}
          >
            <span>Back</span>
          </button>
        </div>

        {/* Dropdown for status filter */}
        <div className="mb-4">
          <label htmlFor="statusFilter" className="block text-sm font-medium text-gray-700">
            Filter by Status:
          </label>
          <select
            id="statusFilter"
            value={statusFilter}
            onChange={handleFilterChange}
            className="mt-1 block w-1/4 px-3 py-2 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          >
            <option value="">All</option>
            <option value="Pending">Pending</option>
            <option value="Preparing">Preparing</option>
            <option value="On the Way">On the Way</option>
            <option value="Delivered">Delivered</option>
            <option value="Cancelled">Cancelled</option>
          </select>
        </div>

        {loading ? (
          <p>Loading...</p>
        ) : (
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Order ID</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Order Status</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Food Name</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Food Image</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Shop Name</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Quantity</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Total Cost</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Shipping Charge</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {currentOrders.map((order) => (
                  <tr key={order.order_id}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{order.order_id}</td>
                    <td className={`px-6 py-4 whitespace-nowrap text-sm ${getOrderStatusClass(order.orderStatus)}`}>
                      {order.orderStatus}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{order.name}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      <div className="w-16 h-16 overflow-hidden rounded-lg">
                        <img
                          src={order.image}
                          alt={order.name}
                          className="w-full h-full object-cover"
                        />
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{order.shopName}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{order.quantity}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-semibold text-blue-600">{order.totalCost}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{order.shippingCharge}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="pagination-controls mt-4">
              <button
                onClick={handlePrevPage}
                disabled={currentPage === 1}
                className="px-4 py-2 mx-2 bg-gray-200 text-gray-700 rounded-md"
              >
                Previous
              </button>
              <button
                onClick={handleNextPage}
                disabled={indexOfLastOrder >= filteredOrders.length}
                className="px-4 py-2 mx-2 bg-gray-200 text-gray-700 rounded-md"
              >
                Next
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default UserDashboard;
