import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { addressUpdate, getFoodFlyUserAddress,foodFlyMobileNumberUpdate } from '../api/Api';
import toast, { Toaster } from 'react-hot-toast';

const Profile = () => {
    const [activeTab, setActiveTab] = useState('mobile');
    const [showProfile, setShowProfile] = useState(false);
    const [dNo, setDNo] = useState('');
    const [street, setStreet] = useState('');
    const [addressLine1, setAddressLine1] = useState('');
    const [addressLine2, setAddressLine2] = useState('');
    const [postCode, setPostCode] = useState('');
    const [city, setCity] = useState('');
    const [district, setDistrict] = useState(''); // State to hold the district value
    const [landmark, setLandmark] = useState('');
    const [addressData, setAddressData] = useState(null);
    const [mobileNumber, setMobileNumber] =useState(null);




    const navigate = useNavigate();
    const mobileNumberChangRequest = (e) => {
        e.preventDefault();
      
        const email = localStorage.getItem(email) || '';  // Get the email from local storage
        const mobileNumberChangeRequest = {
          mobileNumber: mobileNumber,  // Payload for the API request
        };
      
        if (!mobileNumber || mobileNumber.length !== 10) {
          toast.error('Please enter a valid 10-digit mobile number');
          return;
        }
      
        // Call the API
        foodFlyMobileNumberUpdate(email, mobileNumberChangeRequest)
          .then((res) => {
            toast.success(res.data.message || 'Mobile number updated successfully!');
            navigate('/');  // Redirect on success
          })
          .catch((error) => {
            // Handle the error gracefully
            console.error('Mobile number update error:', error);
      
            if (error.response && error.response.data.message) {
              toast.error(error.response.data.message);
            } else {
              toast.error('Sorry! Something went wrong. Please try again!');
            }
          });
      };
      
    const addressChangeSubmit = (e) => {
        e.preventDefault();

        // Log the current state of the district
        console.log("Selected District:", district);

        const addressUpdateRequest = {
            doorNo: dNo,
            streetAddress: street,
            addressLine1: addressLine1,
            addressLine2: addressLine2,
            postCode: postCode,
            city: city,
            district: district, // Include the selected district here
            landMark: landmark,
        };

        addressUpdate(addressUpdateRequest)
            .then((res) => {
                toast.success(res.data.message);
                navigate('/'); // Navigate to home page after success
            })
            .catch((error) => {
                if (error.message) {
                    toast.error(error.message);
                } else {
                    toast.error('Sorry! Something went wrong. Please try again!');
                }
            });
    };

    useEffect(() => {
        if (activeTab === 'view') {
            getFoodFlyUserAddress()
                .then((res) => {
                    if (res.data.success) {
                        setAddressData(res.data.data);
                    } else {
                        toast.error(res.data.message);
                    }
                })
                .catch((error) => {
                    toast.error('Failed to fetch address data. Please try again!');
                });
        }
    }, [activeTab]);

    return (
        <div className="max-w-[900px] mx-auto p-4">
            <Toaster
                position="bottom-right"
                reverseOrder={false}
                toastOptions={{
                    error: {
                        duration: 6000,
                        style: {
                            background: '#FF4136',
                            color: '#fff'
                        }
                    },
                    success: {
                        duration: 6000,
                        style: {
                            background: '#008000',
                            color: '#fff'
                        }
                    }
                }}
            />
            <div className="flex justify-between items-center mb-4">
                <h1 className="text-3xl font-bold">Profile Settings</h1>
                <button
                    className="bg-gray-300 py-2 px-4 rounded text-black hover:bg-gray-400"
                    onClick={() => navigate("/")}
                >
                    Back
                </button>
            </div>

            <div className="flex justify-between mb-8">
                <button
                    className={`py-2 px-4 ${activeTab === 'mobile' ? 'bg-blue-500 text-white' : 'bg-gray-300'}`}
                    onClick={() => setActiveTab('mobile')}
                >
                    Update Mobile Number
                </button>
                <button
                    className={`py-2 px-4 ${activeTab === 'address' ? 'bg-blue-500 text-white' : 'bg-gray-300'}`}
                    onClick={() => setActiveTab('address')}
                >
                    Update Address
                </button>
                <button
                    className={`py-2 px-4 ${activeTab === 'view' ? 'bg-blue-500 text-white' : 'bg-gray-300'}`}
                    onClick={() => {
                        setActiveTab('view');
                        setShowProfile(!showProfile);
                    }}
                >
                    {showProfile ? 'Hide Profile' : 'View Profile'}
                </button>
            </div>

            {activeTab === 'mobile' && (
                <form onSubmit={mobileNumberChangRequest}className="space-y-4">
                    <div>
                        <label className="block text-lg font-medium mb-2">New Mobile Number</label>
                        <input
                         onChange={(e) => setMobileNumber(e.target.value)}
                            type="text"
                            placeholder="Enter new mobile number"
                            className="w-full p-2 border border-gray-300 rounded"
                        />
                    </div>

                    <button
                        type="submit"
                        className="w-full bg-blue-500 text-white py-2 rounded"
                    >
                        Update Mobile Number
                    </button>
                </form>
            )}

            {activeTab === 'address' && (
                <form onSubmit={addressChangeSubmit} className="max-w-md mx-auto bg-white p-6 rounded-lg shadow-md mt-8 space-y-6">
                    <h2 className="text-2xl font-bold text-center mb-4">Update Address</h2>
                    <div>
                        <label className="block text-lg font-medium mb-2">D.No</label>
                        <input
                            onChange={(e) => setDNo(e.target.value)}
                            type="text"
                            placeholder="Enter D.No"
                            className="w-full p-3 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                            required
                        />
                    </div>
                    <div>
                        <label className="block text-lg font-medium mb-2">Street Address</label>
                        <input
                            onChange={(e) => setStreet(e.target.value)}
                            type="text"
                            placeholder="Enter Street Address"
                            className="w-full p-3 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                            required
                        />
                    </div>
                    <div>
                        <label className="block text-lg font-medium mb-2">Address Line 1</label>
                        <input
                            onChange={(e) => setAddressLine1(e.target.value)}
                            type="text"
                            placeholder="Enter Address Line 1"
                            className="w-full p-3 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                            required
                        />
                    </div>
                    <div>
                        <label className="block text-lg font-medium mb-2">Address Line 2</label>
                        <input
                            onChange={(e) => setAddressLine2(e.target.value)}
                            type="text"
                            placeholder="Enter Address Line 2"
                            className="w-full p-3 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div>
                        <label className="block text-lg font-medium mb-2">Post Code</label>
                        <input
                            onChange={(e) => setPostCode(e.target.value)}
                            type="text"
                            placeholder="Enter Post Code"
                            className="w-full p-3 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                            required
                        />
                    </div>
                    <div>
                        <label className="block text-lg font-medium mb-2">City</label>
                        <input
                            onChange={(e) => setCity(e.target.value)}
                            type="text"
                            placeholder="Enter City"
                            className="w-full p-3 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                            required
                        />
                    </div>
                    <div>
                    <label className="block text-lg font-medium mb-2">District</label>
                        <input
                            onChange={(e) => setDistrict(e.target.value)}
                            type="text"
                            placeholder="Enter Your District"
                            className="w-full p-3 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div>
                        <label className="block text-lg font-medium mb-2">Landmark</label>
                        <input
                            onChange={(e) => setLandmark(e.target.value)}
                            type="text"
                            placeholder="Enter Landmark"
                            className="w-full p-3 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <button
                        type="submit"
                        className="w-full bg-blue-500 text-white py-2 rounded hover:bg-blue-600 transition duration-200"
                    >
                        Update Address
                    </button>
                </form>
            )}

            {activeTab === 'view' && showProfile && addressData && (
                <div className="mt-8 p-4 border border-gray-300 rounded">
                    <h2 className="text-2xl font-bold mb-4">Address Details</h2>
                    <p><strong>D.No:</strong> {addressData.doorNo}</p>
                    <p><strong>Street Address:</strong> {addressData.streetAddress}</p>
                    <p><strong>Address Line 1:</strong> {addressData.addressLine1}</p>
                    <p><strong>Address Line 2:</strong> {addressData.addressLine2}</p>
                    <p><strong>Post Code:</strong> {addressData.postCode}</p>
                    <p><strong>City:</strong> {addressData.city}</p>
                    <p><strong>District:</strong> {addressData.district}</p>
                    <p><strong>Landmark:</strong> {addressData.landMark}</p>
                </div>
            )}
        </div>
    );
};

export default Profile;