import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getFoodFlyData } from '../api/PupilApi';
import { toast } from "react-hot-toast";

const ShopDashboard = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedOrders, setSelectedOrders] = useState(null);
  const [shopFilter, setShopFilter] = useState(''); // For filtering by shop name
  const [statusFilter, setStatusFilter] = useState(''); // For filtering by status

  const navigate = useNavigate();

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    setLoading(true);
    getFoodFlyData()
      .then((res) => {
        setData(res.data);
        setLoading(false);
      })
      .catch((error) => {
        if (error.message) {
          toast.error("Error loading data");
        } else {
          toast.error("Sorry! Something went wrong. Please try again!");
        }
        setLoading(false);
        console.log(error);
      });
  };

  const openModal = (orderList) => {
    setSelectedOrders(orderList);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setSelectedOrders(null);
  };

  const handleNavigateBack = () => {
    navigate(-1);
  };

  if (loading) return <p className="text-center">Loading...</p>;
  if (error) return <p className="text-center text-red-500">Error loading data</p>;

  const shops = {};
  if (data && data.content) {
    data.content.forEach(user => {
      if (user.orderList) {
        user.orderList.forEach(order => {
          const shopName = order.shopName || 'N/A';
          if (!shops[shopName]) {
            shops[shopName] = [];
          }
          // Append the user data along with each order for later use in modal
          shops[shopName].push({ ...order, orderedPersonName: user.name, orderedPersonMobile: user.pupilMobile, orderedUserName: user.pupilEmail });
        });
      }
    });
  }

  // Filter orders by shop name and status
  const filteredShops = Object.keys(shops)
    .filter(shop => shopFilter === '' || shop === shopFilter) // Filter by shop name
    .reduce((filtered, shop) => {
      filtered[shop] = shops[shop].filter(order => statusFilter === '' || order.orderStatus === statusFilter); // Filter by status
      return filtered;
    }, {});

  return (
    <div className="container mx-auto p-4">
      <button
        onClick={handleNavigateBack}
        className="mb-4 bg-gray-500 text-white px-4 py-2 rounded-lg"
      >
        Back
      </button>
      <h1 className="text-2xl font-bold mb-4">Shop Dashboard</h1>

      {/* Shop Name Filter */}
      <div className="mb-4">
        <label className="block mb-2 text-sm font-medium text-gray-700">Filter by Shop Name:</label>
        <select
          value={shopFilter}
          onChange={(e) => setShopFilter(e.target.value)}
          className="p-2 border border-gray-300 rounded-md"
        >
          <option value="">All Shops</option>
          {Object.keys(shops).map(shopName => (
            <option key={shopName} value={shopName}>
              {shopName}
            </option>
          ))}
        </select>
      </div>

      {/* Status Filter */}
      <div className="mb-4">
        <label className="block mb-2 text-sm font-medium text-gray-700">Filter by Status:</label>
        <select
          value={statusFilter}
          onChange={(e) => setStatusFilter(e.target.value)}
          className="p-2 border border-gray-300 rounded-md"
        >
          <option value="">All Status</option>
          <option value="Pending">Pending</option>
          <option value="Preparing">Preparing</option>
          <option value="On the way">On the way</option>
          <option value="Delivered">Delivered</option>
          <option value="Cancelled">Cancelled</option>
        </select>
      </div>

      {Object.keys(filteredShops).length > 0 ? (
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white border border-gray-200 table-auto">
            <thead>
              <tr className="w-full bg-gray-100 text-left text-gray-700 border-b border-gray-200">
                <th className="p-3 text-sm md:text-base">Shop Name</th>
                <th className="p-3 text-sm md:text-base">Orders</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(filteredShops).map(([shopName, orders]) => (
                <tr key={shopName} className="border-b border-gray-200">
                  <td className="p-3 text-sm md:text-base">{shopName || 'N/A'}</td>
                  <td className="p-3 text-sm md:text-base">
                    {orders.length > 0 ? (
                      <button
                        onClick={() => openModal(orders)}
                        className="text-blue-500 underline"
                      >
                        View Orders
                      </button>
                    ) : (
                      <p>No orders available</p>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <p className="text-center">No data available</p>
      )}

      {modalOpen && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center p-4">
          <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-3xl relative">
            <div className="space-y-4">
              <h2 className="text-xl font-bold mb-4">Order Details</h2>
              {selectedOrders && selectedOrders.length > 0 ? (
                <div className="overflow-y-auto max-h-[60vh]">
                  <ul className="space-y-4">
                    {selectedOrders.map((order) => (
                      <li key={order.order_id} className="p-4 border border-gray-200 rounded-lg">
                        <p><strong>Order ID:</strong> {order.order_id}</p>
                        <p><strong>Total Cost:</strong> {order.totalCost}</p>
                        <p><strong>Shipping Charge:</strong> {order.shippingCharge}</p>
                        <p><strong>Order Status:</strong> {order.orderStatus}</p>
                        <p><strong>Payment Method:</strong> {order.paymentMethod}</p>

                        {/* User Information */}
                        <p><strong>Ordered Person Name:</strong> {order.orderedPersonName || 'N/A'}</p>
                        <p><strong>Ordered Person Mobile:</strong> {order.orderedPersonMobile || 'N/A'}</p>
                        <p><strong>Ordered Person Username:</strong> {order.orderedUserName || 'N/A'}</p>

                        {/* Food Information */}
                        <div className="flex items-center space-x-4 mt-4">
                          <img
                            src={order.image}
                            alt={order.name}
                            className="w-24 h-24 object-cover rounded-lg"
                          />
                          <div>
                            <p><strong>Food Name:</strong> {order.name}</p>
                            <p><strong>Price:</strong> {order.price}</p>
                           
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              ) : (
                <p>No orders available</p>
              )}
            </div>
            <button
              onClick={closeModal}
              className="absolute top-4 right-4 text-gray-600 hover:text-gray-800"
            >
              X
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ShopDashboard;
