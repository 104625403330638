import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import Navbar from './Navbar';
import Footer from './Footer';
import toast, { Toaster } from "react-hot-toast";
import { ACCESS_TOKEN, USERNAMEWITHMAIL } from "../config/Config";
import { useContextSelector } from "use-context-selector";
import { StateContext } from "../contexts/StateContext";
import { logInOtp, logInOtp2 } from "../api/Api";

const SignInPage = () => {
  const navigate = useNavigate();
  const isLoggedIn = useContextSelector(
    StateContext,
    (state) => state.isLoggedIn
  );
  const setIsLoggedIn = useContextSelector(
    StateContext,
    (state) => state.setIsLoggedIn
  );
  const setRole = useContextSelector(
    StateContext,
    (state) => state.setRole
  );
  const [showLoading, setShowLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [errEmail, setErrEmail] = useState("");
  const [errOtp, setErrOtp] = useState("");

  const handleEmail = (e) => {
    setEmail(e.target.value);
    setErrEmail("");
  };

  const handleOtp = (e) => {
    setOtp(e.target.value);
    setErrOtp("");
  };

  // Function to handle the first step of sign-in (send OTP)
  const handleSignIn = (e) => {
    e.preventDefault();

    if (!email) {
      setErrEmail("Enter your email");
      return;
    }

    setShowLoading(true);

    const loginRequest = {
      usernameOrEmail: email,
      orgName: "foodfly",


    };

    // Call the login-otp API
    logInOtp(loginRequest)
      .then((res) => {
        setIsOtpSent(true);
        toast.success("OTP sent to your email!");
      })
      .catch((error) => {
        toast.error("Error sending OTP. Please try again.");
      })
      .finally(() => {
        setShowLoading(false);
      });
  };

  // Function to handle OTP verification
  const handleVerifyOtp = (e) => {
    e.preventDefault();

    if (!otp) {
      setErrOtp("Enter OTP");
      return;
    }

    setShowLoading(true);

    const otpRequest = {
      email: email,
      otp: otp,
    };

    // Call the signing-step2 API for OTP validation
    logInOtp2(otpRequest)
      .then((res) => {
        localStorage.setItem(ACCESS_TOKEN, res.accessToken || null);
        localStorage.setItem(USERNAMEWITHMAIL, email);
        setIsLoggedIn(true);

        const token = res.accessToken;
        const base64Url = token.split(".")[1];
        const base64 = base64Url.replace("-", "+").replace("_", "/");
        const decodedToken = JSON.parse(window.atob(base64));

        if (decodedToken.exp <= Math.floor(Date.now() / 1000)) {
          localStorage.removeItem(ACCESS_TOKEN);
          setIsLoggedIn(false);
        }

        if (decodedToken.rol && decodedToken.rol.length >= 1) {
          setRole(decodedToken.rol[0]);

        }

        toast.success("OTP verified successfully!");
        navigate("/"); // Navigate to the homepage after successful OTP verification
      })
      .catch((error) => {
        toast.error("OTP verification failed. Please try again.");
        setErrOtp("Invalid OTP. Please check and try again.");
      })
      .finally(() => {
        setShowLoading(false);
      });
  };

  const handleBack = () => {
    if (isOtpSent) {
      setIsOtpSent(false); // Reset to email input
      setOtp(""); // Clear OTP input
      setErrOtp(""); // Clear OTP error
    } else {
      navigate(-1); // Go back to the previous page
    }
  };

  return (
    <>
      <Navbar />
      <div className="flex items-center justify-center min-h-screen bg-gray-100">
        <div className="w-full max-w-md p-8 space-y-6 bg-white rounded-lg shadow-lg">
          <h1 className="text-3xl font-extrabold text-gray-900">
            {isOtpSent ? "Enter OTP" : "Sign In"}
          </h1>
          <button
            onClick={handleBack}
            className="mb-4 text-blue-600 hover:underline"
          >
            Back
          </button>
          <form className="space-y-4" onSubmit={isOtpSent ? handleVerifyOtp : handleSignIn}>
            {!isOtpSent ? (
              <>
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Email
                  </label>
                  <input
                    id="email"
                    name="email"
                    type="email"
                    onChange={handleEmail}
                    value={email}
                    placeholder="Enter your email"
                    className={`mt-1 w-full px-4 py-2 border ${errEmail ? "border-red-500" : "border-gray-300"} rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500`}
                  />
                  {errEmail && <p className="text-sm text-red-500 mt-1">{errEmail}</p>}
                </div>
                <div>
                  <button
                    type="submit"
                    className="w-full py-3 text-base font-medium text-white bg-blue-600 rounded-md shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    disabled={showLoading}
                  >
                    {showLoading ? "Sending OTP..." : "Sign In"}
                  </button>
                </div>
              </>
            ) : (
              <>
                <div>
                  <label
                    htmlFor="otp"
                    className="block text-sm font-medium text-gray-700"
                  >
                    OTP
                  </label>
                  <input
                    id="otp"
                    name="otp"
                    type="text"
                    onChange={handleOtp}
                    value={otp}
                    placeholder="Enter the OTP sent to your email"
                    className={`mt-1 w-full px-4 py-2 border ${errOtp ? "border-red-500" : "border-gray-300"} rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500`}
                  />
                  {errOtp && <p className="text-sm text-red-500 mt-1">{errOtp}</p>}
                </div>
                <div>
                  <button
                    type="submit"
                    className="w-full py-3 text-base font-medium text-white bg-blue-600 rounded-md shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    disabled={showLoading}
                  >
                    {showLoading ? "Verifying OTP..." : "Verify OTP"}
                  </button>
                </div>
              </>
            )}
            {!isOtpSent && (
              <p className="text-sm text-center text-gray-600">
                Don't have an account?{" "}
                <Link to="/signup" className="font-medium text-blue-600 hover:underline">
                  Sign up
                </Link>
              </p>
            )}
          </form>
        </div>
        <Toaster
          position="bottom-right"
          reverseOrder={false}
          toastOptions={{
            error: {
              duration: 6000,
              style: {
                background: "#FF4136",
                color: "#fff",
              },
            },
            success: {
              duration: 6000,
              style: {
                background: "#008000",
                color: "#fff",
              },
            },
          }}
        />
      </div>
      <Footer />
    </>
  );
};

export default SignInPage;
